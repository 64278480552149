export default function SampleCardPlaceholder() {
    return <>
        <div class="card mx-auto shadow-lg my-2 border-secondary" style={{"width" : "90%"}}>
            <img src="..." class="card-img-top" alt="..." />
            <div class="card-body">
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-2" />
                </p>
            </div>
        </div>
    </>
}