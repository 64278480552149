import { Link } from "react-router-dom"

import FullLogo from "../images/my_mini_prints_logo_header_small.webp"
import Image1 from "../images/Homepage/hand_foot_photo_white_granite_landscape.webp"
import Image2 from "../images/Homepage/3d_landscape_hhff_pink_silvercasts.webp"
import Image3 from "../images/Homepage/IMG_0662.webp"
import Image4 from "../images/Homepage/IMG_3965.webp"
import Image5 from "../images/Homepage/2hands_2feet_photo_oak_cream.webp"
import Image6 from "../images/Homepage/1024_hhff_photo_portrait_white_silvercasts.webp"
import Image7 from "../images/Homepage/1024_baby_hand_casting.webp"


export default function HomePage() {
    return <>

        <div class="container-fluid text-center">
            <div class="row">
            

            
            <div class="container" style={{"display" : "flex", "justifyContent" : "center"}} >
                <img src={FullLogo} class="MMP-logo-name img-fluid" alt="My Mini Prints"></img>
            </div>

            
            <div class="container">
                <br></br>
                <br></br>
                <div class="card mx-auto p-0 shadow" style={{"width" : "100%"}}>
                    <h1 class="card-header h1 display-4" style={{"backgroundColor": "#DCE774", "color": "#504E51"}}>Welcome to My Mini Prints</h1>
                    <p class="card-body display-6">Hand and Foot casting</p>
                    <p class="card-body me-auto">

                        My Mini Prints produce beautiful high quality hand and feet print casts of your baby and children. We offer a wide range of frames and mount colours so your keepsake casting is totally bespoke and unique. A wonderful way to remember how little they once were.
                        <br></br>
                        <br></br>
                        <img src={Image1} class="MMP-general-image rounded img-fluid" alt="Hand & foot cast on granite backing"  />
                        <br></br>
                        <br></br>
                        <img src={Image2} class="MMP-general-image rounded img-fluid" alt="Hand & foot 3D on grey backing"  />
                    </p>
                </div>
                <br></br>
                <br></br>
                <div class="card mx-auto p-0 shadow" style={{"width" : "100%"}}>
                    <h2 class="card-header">3D Castings</h2>
                    <div class="card-body">
                        <br></br>
                        <br></br>
                        <img src={Image3} class="MMP-general-image rounded img-fluid" alt="Hand and foot casts with a letter in frame"  loading="lazy" />
                        <br></br>
                        <br></br>
                        <img src={Image4} class="MMP-general-image rounded img-fluid" alt="Hand and foot casts with a photo in frame"  loading="lazy" />
                    </div>
                </div>
                <br></br>
                <br></br>
                <div class="card mx-auto p-0 shadow" style={{"width" : "100%"}}>
                    <h2 class="card-header">Impressions</h2>
                    <div class="card-body">
                        <br></br>
                        <br></br>
                        <img src={Image5} class="MMP-general-image rounded img-fluid" alt="Hand and feet impressions"  loading="lazy" />
                        <br></br>
                        <br></br>
                        <img src={Image6} class="MMP-general-image rounded img-fluid" alt="Silvered hand and feet impressions"  loading="lazy" />
                    </div>
                </div>
                <br></br>
                <br></br>
                <div class="card mx-auto p-0 shadow" style={{"width" : "100%"}}>
                    <h2 class="card-header">Prices</h2>
                    <div class="card-body">
                            <Link class="btn btn-lg btn-success" role="button" to="prices" >Click for Pricelist</Link>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div class="card mx-auto p-0 shadow" style={{"width" : "100%"}}>
                    <h2 class="card-header">Casting Appointment</h2>
                    <div class="card-body">
                        <p>Suitable from birth to adult. Taking the casts takes minutes, it usually takes longer for you to decide which frame, colour, and layout you would like!</p>
                        <br></br>
                        <br></br>
                        <img src={Image7} class="MMP-general-image rounded img-fluid" alt="Image of a casting appointment"  loading="lazy" />
                        <br></br>
                        <br></br>
                        <Link class="btn btn-secondary btn-lg btn-success" role="button" to="howtobook#top">Click for Booking instructions</Link>
                    </div>
                </div>
                <br></br>
                <br></br>
            </div>

            </div>
        </div>


            

    </>
}

