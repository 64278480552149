import PriceTable from "../components/mmp-pricetable"

export default function PricePage() {
    return <>


            <div class="container">

                <br></br>
                    <div class="card shadow">
                        <h1 class="card-header h1 display-4" style={{"backgroundColor": "#DCE774", "color": "#504E51"}}>Price List</h1>
                    </div>
                <br></br>

                <div class="card mx-auto p-1 shadow" style={{"width" : "100%"}}>

                    <div class="card-body">
                        <p class="card-text">
                        The wide choice of frames is complemented with a range of layout options, over 20 mountboard colours, and several frame finishes for you to choose from at the time that the casts are taken.
                        <br></br>
                        <br></br>
                        Below are a list of our more popular combinations however we can make bespoke orders to suit your requirements too. 
                        <br></br>
                        <br></br>
                        Colours and finishes do not alter the listed price as our prices are based on the cost of materials and the time it takes me to hand-craft each cast and frame.
                        </p>
                    </div>
                    
                </div>
                <br></br>
                
            </div>
            <PriceTable></PriceTable>
            
            <br></br>
            <br></br>

    </>
}
