import { LazyLoadComponent } from 'react-lazy-load-image-component'

import samplesData from "../refdata/samples.json"
import SampleCard from "../components/mmp-sample-card"
import SampleCardPlaceholder from '../components/mmp-sample-card-placeholder'

export default function SamplesPage() {
    return <>
        <div class="container">

        <br></br>
            <div class="card shadow">
                <h1 class="card-header display-4" style={{"backgroundColor": "#DCE774", "color": "#504E51"}}>Samples</h1>
            </div>
        <br></br>

        <div class="card mx-auto p-1 shadow" style={{"width" : "100%"}}>
            <div class="card-body">
                <p class="card-text">
                    Here is a selection of my previous work for you to view.
                    <br></br>
                    <br></br>
                    Please note that these are examples of what I can do, but I have many other options for colour combinations and layouts - do not feel that you are restricted by these examples as I am happy to work with you to design exactly the keepsake that you want.
                </p>
            </div>
            
        </div>
        <br></br>
            
            <div class="row">
                {samplesData.Samples.map((sample) => (
                <>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <LazyLoadComponent placeholder={<SampleCardPlaceholder></SampleCardPlaceholder>} >
                            <SampleCard title={sample.title} image={samplesData.imgPath + sample.imageFile}></SampleCard>
                        </LazyLoadComponent>
                    </div>
                </>
                ))}

            </div>
            <br></br>
        </div>


        <br></br>
        <br></br>

    </>
}