import { Outlet } from "react-router"
import MMPNavbar from "../components/mmp-navbar"
import MMPFooter from "../components/mmp-footer"

export default function Root() {
    return <>
        <MMPNavbar />

        <div id="detail">
            <Outlet />
        </div>

        <MMPFooter />
    </>
}