import reviewsData from "../refdata/reviews.json"
import ReviewCard from "../components/mmp-review-card"

export default function AboutPage() {
    return <>

        <div class="container">

            <br></br>
                <div class="card shadow">
                    <h1 class="card-header h1 display-4" style={{"backgroundColor": "#DCE774", "color": "#504E51"}}>About Us</h1>
                </div>
            <br></br>

            <div class="card mx-auto p-1 shadow" style={{"width" : "100%"}}>
                <div class="card-body">
                    <p class="card-text">
                    Hi, I am Sue Tarbard and am passionate about what I create. I have had a creative streak for as long as I can remember and it is wonderful to be able to use this within my work. 
                    <br></br>
                    <br></br>
                    As a child I had a love of drawing and colouring alongside painting numerous plaster of paris models that I had made in the kitchen. Later, as an outlet for my creative side, whilst working in London for a large Investment Company I enrolled in weekly calligraphy classes. After leaving the corporate world in 2004 to be with my young children I found that I had the opportunity to embark on a new career producing various unique keepsakes for little ones. 
                    <br></br>
                    <br></br>
                    At My Mini Prints I feel privileged to produce a range of keepsakes, that will be treasured by parents and children of today, and passed down through the generations of tomorrow. 
                    </p>
                </div>
                
            </div>
        <br></br>

        <h2 class="mx-auto p-1">Reviews</h2>

        {reviewsData.Reviews.map((review) => (
            <>
                <ReviewCard review={review.ReviewText} reviewer={review.ReviewerName}></ReviewCard>
                <br></br>
            </>
        ))}
        </div>

        <br></br>
        <br></br>

    </>

}