import IconMail from "../images/icons/envelope.svg"
import IconPhone from "../images/icons/phone.svg"
import IconFacebook from "../images/icons/facebook.svg"
import IconInstagram from "../images/icons/instagram.svg"

export default function MMPFooter() {
    return <>
    <footer>
        <div class="container">
            <div class="card" style={{"width" : "100%"}}>
                <div class="card-header">Contact Us</div>
                <div class="card-body">
                    <div class="row">
                        <div class="card-group">
                            
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item"><a href="mailto:info@myminiprints.co.uk?subject=Website%20Enquiry"><img class="p-1" src={IconMail} alt="email" width="32" height="32"></img><small>info@myminiprints.co.uk</small></a></li>

                                    <li class="list-group-item"><a href="sms:07771754144"><img class="p-1" src={IconPhone} alt="phone" width="32" height="32"></img><small>07771 754144</small></a></li>

                                    <li class="list-group-item"><a href="https://www.facebook.com/MyMiniPrintsBabyHandAndFootCastingEssex/?ref=bookmarks" ><img class="p-1" src={IconFacebook} alt="Facebook" width="32" height="32"></img><small>MyMiniPrintsBabyHandAndFootCastingEssex</small></a></li>

                                    <li class="list-group-item"><a href="https://www.instagram.com/myminiprintsbabycasting" ><img class="p-1" src={IconInstagram} alt="Facebook" width="32" height="32"></img><small>@myminiprints - babycasting</small></a></li>
                                </ul>
                            </div>
                            
                            <div class="card">
                                <div class="card-title"><p class="fw-bold p-1">Address</p></div>
                                <p class="p-1">
                                8 Smythe Close<br></br>   
                                Billericay, Essex<br></br>
                                CM11 1SF
                                </p>
                            </div>
                        


                        </div>
                        
                    
                    </div>
                    
                </div>
                <div class="row">
                    <div>
                        <p class="fw-light"><small>
                        Baby Hand And Foot Casting across Essex and surrounding areas:- Basildon, Benfleet, Billericay, Brentwood, Braintree, Brentwood, Canvey Island, Chelmsford, Colchester, Epping, Grays, Great Dunmow, Halstead, Hornchurch, Hockley, Kelvedon, Leigh-on-Sea, Maldon, Rayleigh, Rochford, Romford, Shoeburyness, Stanford-le-Hope, South Woodham Ferrers, Southend, South Ockenden, Thurrock, Upminster, Westcliff-on-Sea, Wickford, Witham. 
                        </small></p>
                    </div>
                </div>
            </div>
            <div>
                <p class="fw-lighter"><small>Copyright &copy; My Mini Prints Baby Hand And Foot Casting Essex 2024</small></p>
            </div>
        </div>
    </footer>
    
    </>
}