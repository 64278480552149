import React from 'react';
import ReactDOM from 'react-dom/client';

// Import React Router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Import Bootstrap framework
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Import website routes
import Root from './routes/root';
import HomePage from './routes/home-page';
import ErrorPage from './routes/error-page';
import AboutPage from './routes/about-page';
import BlogPage from './routes/blog-page';
import PricePage from './routes/prices-page';
import HowToBook from './routes/howtobook-page';
import SamplesPage from './routes/samples-page';
import './index.css';

import reportWebVitals from './reportWebVitals';





const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <HomePage />,
        index: true,
      },
      {
        path: "/AboutUs.html",
        element: <AboutPage />,
      },
      {
        path: "/about.html",
        element: <AboutPage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/blog",
        element: <BlogPage />,
      },
      {
        path: "/Blog.html",
        element: <BlogPage />,
      },
      {
        path: "/samples",
        element: <SamplesPage />,
      },
      {
        path: "/prices",
        element: <PricePage />,
      },
      {
        path: "/prices.html",
        element: <PricePage />,
      },
      {
        path: "/howtobook.html",
        element: <HowToBook />,
      },
      {
        path: "/howtobook",
        element: <HowToBook />,
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
