
export default function HowToBook() {
    return <>

            <div class="container">

                <br></br>
                    <div class="card shadow">
                        <h1 class="card-header h1 display-4" style={{"backgroundColor": "#DCE774", "color": "#504E51"}}>How to Book</h1>
                    </div>
                <br></br>
                
                <div class="card mx-auto p-1 shadow" style={{"width" : "100%"}}>
                    <div class="card-body">
                        <p class="card-text">
                        I have a home studio in Billericay that I work from, and you are welcome to come and have a look at the selection of example casts and frames that I keep in my studio  with no obligation to buy.  If you have any questions, would like to come and view my products, or wish to book an appointment please get in touch with me - my contact details are at the bottom of every page on this website.
                        <br></br>
                        <br></br>
                        I can be contacted via Facebook, Instagram, email, or phone to make an appointment and can usually book you a time within the next 2 weeks.
                        <br></br>
                        <br></br>
                        On the day of the appointment I know how difficult it is to plan when you have a little one, so please feel free to feed your young baby or change a nappy during your appointment if needed.  You will have plenty of time to view the example frames, discuss options, decide on what you would like, and - of course - take the hand and/or feet impressions.
                        <br></br>
                        <br></br>
                        When you do place an order, I require a £40 deposit per frame at the time of taking the impressions with the balance due when the product is ready for collection approximately 8 weeks later.  There can be some flexibility in the options up to the point where I start the work on the frame and - if you want a photo mounted in the frame - you can send me your chosen photos at a later date.  Please note that late decisions on options or sending photos may delay the completion of your frame beyond the estimated 8 weeks.
                        </p>
                    </div>
                    
                </div>
                <br></br>
                <div class="card mx-auto p-1 shadow" style={{"width" : "100%"}}>
                    <h2 class="card-header">How to Find Us</h2>
                    <div class="card-body">
                        <p class="card-text">
                        <h3 class="text-center">Sue Tarbard</h3> 
                        <h3 class="text-center">8 Smythe Close, Billericay, </h3>
                        <h3 class="text-center">Essex, CM11 1SF</h3>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div class="container" style={{"display" : "flex", "justifyContent" : "center"}}>
                        <div class="d-md-none">
                                    <iframe class="mx-auto" title="GoogleMaps small" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2476.096144695516!2d0.4374133153575158!3d51.639764308838096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8c1083cd800ad%3A0x45b46401b5ba36e0!2sMy+Mini+Prints+Baby+Hand+and+Feet+Casts+%2F+Impressions+%2F+Essex!5e0!3m2!1sen!2suk!4v1524419118996&key=AIzaSyD1o7NvVFOVOD3xurnrq70cxvCKnPOBkLw" width="280" height="450" frameborder="0" 
                                    style={{"borderTop" : "0 solid", "borderBottom" : "0", "borderLeft" : "0 solid", "borderRight" : "0", "overflow" : "scroll", "boxShadow" : "2 1 2 #0c0c0c"}} allowfullscreen></iframe>
                        </div>
        
                        <div class="d-none d-md-block">
                            <iframe class="mx-auto" title="GoogleMaps large" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2476.096144695516!2d0.4374133153575158!3d51.639764308838096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8c1083cd800ad%3A0x45b46401b5ba36e0!2sMy+Mini+Prints+Baby+Hand+and+Feet+Casts+%2F+Impressions+%2F+Essex!5e0!3m2!1sen!2suk!4v1524419118996&key=AIzaSyD1o7NvVFOVOD3xurnrq70cxvCKnPOBkLw" width="600" height="450" frameborder="0" 
                            style={{"borderTop" : "0 solid","borderBottom" : "0","borderLeft" : "0 solid","borderRight" : "0","overflow" : "scroll","boxShadow" : "2 1 2 #0c0c0c"}} allowfullscreen></iframe>
                        </div>
                        </div>
                        <br></br>
                        <br></br>

                        </p>
                    </div>
                    
                </div>
                <br></br>


            <br></br>
            </div>

    </>
}