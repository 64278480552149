export default function ReviewCard(props) {
    return <>
        <div class="card mx-auto shadow-lg" style={{"width" : "90%"}}>
            <div class="card-body">
                <blockquote class="blockquote mb-0">
                    <p>"{props.review}"</p>
                    <br></br>
                    <footer class="blockquote-footer">{props.reviewer}</footer>
                </blockquote>
            </div>
        </div>
    </>
}
