import { Link } from "react-router-dom"

import NameGraphic from "../images/my_mini_prints_logo_name_only.webp"

export default function MMPNavbar() {
    return <>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <a class="navbar-brand" href="/">
                    <img src={NameGraphic} alt="My Mini Prints company logo" width="140" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <Link to="/" class="nav-link active"  aria-current="true">Home</Link>
                        </li>
                        <li>
                        <Link to="samples" class="nav-link" data-bs-target=".navbar-collapse.hide" >Samples</Link>
                        </li>
                        <li>
                        <Link to="prices" class="nav-link" data-bs-target=".navbar-collapse.hide" >Prices</Link>
                        </li>
                        <li class="nav-item">
                            <Link to="howtobook" class="nav-link" data-bs-target=".navbar-collapse.hide" >How to Book</Link>
                        </li>
                        <li class="nav-item">
                            <Link to="about" class="nav-link" data-bs-target=".navbar-collapse.hide" >About</Link>
                        </li>
                        <li class="nav-item">
                            <Link to="blog" class="nav-link" data-bs-target=".navbar-collapse.hide" >Blog</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    
    </>
}
