import priceTableData from "../refdata/prices.json"

export default function PriceTable() {
    return <>
        <div>
            <div class="container">
            
            {priceTableData.Tables.map((ptable) => (
                <div key={ptable.TableName}>
                    <card class="card mx-auto shadow-lg" style={{"width" : "100%"}}>

                        <div class="card-header"><h3 class="fw-bold">{ptable.TableName}</h3></div>
                        <div class="card-body">

                        <table class={"table table-bordered table-hover table-striped " + ptable.Colour}>
                            <thead class="table-dark">
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ptable.Rows.map((row) => (

                                        <tr key={row.Text}><th scope="row">{row.Text}</th>
                                            <td class="text-end">{row.Price}</td>
                                        </tr>

                                ))}
                            </tbody>

                        </table>
                        </div>
                        <p class="fw-lighter p-1"><small>The above prices are applicable from {priceTableData.ApplicableDate} </small></p>
                    </card>
                    <br></br>
                    
                </div>
            ))}
            </div>

            
        </div>
    </>
}