import Image3 from "../images/Blog/1024_baby_foot_casting.webp"
import Image2 from "../images/Blog/first_birthday_blog.webp"
import Image1 from "../images/Blog/workshop.webp"

export default function BlogPage() {
    return <>


                <div class="container">
                   
                    <br></br>
                    <div class="card shadow">
                        <h1 class="card-header h1 display-4" style={{"backgroundColor": "#DCE774", "color": "#504E51"}}>My Blog</h1>
                    </div>
                    <br></br>
                    <div class="card shadow">
                        
                        <img src={Image3} class="MMP-blog-image rounded mx-auto img-fluid" alt="Baby during casting" loading="lazy" />
                        <div class="card-body">
                            <h2 class="card-title">
                                What is the best age to get a hand and foot casting taken for my baby / child?
                            </h2>
                            <p class="card-text">
                                <br></br>
                                The questions I am asked frequently include:-
                                <br></br>
                                <br></br>
                                <em>“How old does my baby have to be to have her hand and foot cast taken?”</em>
                                <br></br>
                                <em>“What is the youngest baby you have cast?”</em>
                                <br></br>
                                <em>“My baby is xx months old – have I left it too late?”</em>
                                <br></br>
                                <em>“Can you take hand and foot impressions of toddlers?”</em>
                                <br></br>
                                <em>“Can we have our hands cast with our children?”</em>
                                <br></br>
                                <br></br>
                                The method I use to make our casts means that I can take hand and foot impressions of babies from birth right up to adult. I gently press the hand or foot into a soft terracotta natural clay and this is used as a mold to make the cast.
                                <br></br>
                                <br></br>
                                Customers often comment that they were bought a kit when the baby was born but they couldn’t get baby to open his hand or the materials set too quick etc. There is a knack to taking good hand and foot impressions and my 14 years of experience of working with babies and young children enables me to get great hand and foot prints in a relaxed manner and environment.
                                <br></br>
                                <br></br>
                                I always feel a little sad when customers think they have left it too late to have their little one’s hands / feet impressions taken. Six months to 24 month olds often have lovely little chubby hands and feet and I think their personality at this age is reflected in their casts, especially if coupled with a photo. Preschool children are a great age to cast too as their hand shape is changing from a toddler’s to a child’s.
                                <br></br>
                                <br></br>
                                <strong>So to answer the questions above...</strong>
                                <br></br>
                                <br></br>

                                <em>How old does my baby have to be to have her hand and foot cast taken?</em> ... Any age!
                                <br></br>
                                <br></br>
                                <em>What is the youngest baby you have cast?</em> ... 2 days!
                                <br></br>
                                <br></br>
                                <em>My baby is xx months old – have I left it too late?</em> ... no, most definitely not!
                                <br></br>
                                <br></br>
                                <em>Can you take hand and foot impressions of toddlers?</em> ... Yes!
                                <br></br>
                                <br></br>
                                <em>Can we have our hands cast with our children?</em> ... Yes, but I wouldn’t recommend feet as we all know adult feet are not so cute!
                            </p>
                        </div>
                    </div>
                    <br></br>
                    <div class="card shadow">
                        <img src={Image2} class="MMP-blog-image rounded mx-auto img-fluid" alt="Hand Foot casts framed" loading="lazy"/>
                        <div class="card-body">
                            <h2 class="card-title">
                                First Birthdays
                            </h2>
                            <p class="card-text">
                                <br></br>
                                There’s nothing quite so special as when your baby reaches his /her first birthday, it’s a great time for families to celebrate this milestone in their little one’s life.
                                <br></br>
                                <br></br>
                                For parents it is often a time to reflect on how much has changed the in past year. How baby has changed, from being a tiny, helpless newborn, through sitting, crawling, standing, maybe even taking a few steps. Not to mention some teething and the messy task of weaning . By their first birthday, babies have their own little personalities that we know and love so much but there is often a fleeting feeling of sadness that time is going too quick and sometimes we would like life to slow down for a while.
                                <br></br>
                                <br></br>
                                Many parents want to buy their baby a special first birthday present to keep. Our framed hand and foot casts are wonderful ways of encapsulating everything that a first birthday represents. Some parents like to include a photo too, maybe one of their child on their special day. 
                                <br></br>
                                <br></br>
                                Grandparents and friends often ask for inspirations of what to buy – why not suggest a My Mini Prints gift voucher?
                            </p>
                        </div>
                    </div>
                    <br></br>
                    <div class="card shadow">
                        <img src={Image1} class="MMP-blog-image rounded mx-auto img-fluid" alt="Workshop in garden" loading="lazy" />
                        <div class="card-body">
                            <h2 class="card-title">
                                The “Back Office”
                            </h2>
                            <p class="card-text">
                                <br></br>
                                Hi, it is a lovely sunny day at My Mini Prints HQ, so what better time to sit on the patio and create my Blog page.
                                <br></br>
                                <br></br>
                                I have lots of ideas of posts to make and will enjoy sharing them with you. Some customers make appointments to come to my home studio and some I meet at events and classes, however all of your orders are processed through my workshop (see image above). 
                                <br></br>
                                <br></br>
                                This is where all the dusty, mucky work is carried out turning your clay molds into works of art. On days like today my workshop is toasty warm but in the winter it can be jolly chilly.  Whatever the weather our dog likes to be on guard while I am working inside!
                            </p>
                        </div>
                    </div>
                </div>
            <br></br>
            <br></br>


    </>
}
